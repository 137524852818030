<template>
<div class="reset-page">
    <v-layout row class="login-form" >
        <div class="overlay">
            <LoginForm header="Reset Password" @emitResetPassword="reset" />
        </div>
    </v-layout>
   <!--<v-snackbar
      v-model="snackbarReset"
      bottom right
      :timeout='timeout'
    >
      {{ successMessage }}
    </v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="successMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_TIMEOUT } from '@/constants';
import LoginForm from '@/common/LoginForm.vue';
import Dialog from '@/common/ErrorDialog.vue';

export default {
  components: {
    LoginForm,
    Dialog,
  },
  data() {
    return {
      openDialog: false,
      snackbarReset: false,
      timeout: SNACKBAR_TIMEOUT,
    };
  },
  computed: {
    ...mapState({
      successMessage: state => state.user.sucessMessage,
    }),
  },
  watch: {
    successMessage() {
      if (this.successMessage !== null) {
        this.snackbarReset = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
  },
  methods: {
    ...mapActions(['resetPassword', 'resetSuccessMessage']),
    reset(email) {
      this.resetPassword({ email, userType: 'APP' });
    },
    resetError() {
      this.snackbarReset = false;
      this.resetSuccessMessage();
    },
    closeDialog() {
      this.openDialog = false;
      this.resetSuccessMessage();
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
    height: 100%;
    position: relative;
    background-image: url("../assets/Relaxing in hotel-1-min.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0px 50px 0px;
    }
}
@media only screen and (max-width: 768px) {
    .login-form {
        .v-card {
            min-width: 100% !important;
        }
    }
}
.reset-page {
  height: 100%;
  .form-outline {
    height: 100%;
  }
}
</style>
