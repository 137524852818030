<template>
  <ResetPassword />
</template>

<script>
import { mapState } from 'vuex';
import ResetPassword from '@/components/ResetPassword.vue';

export default {
  components: {
    ResetPassword,
  },
  computed: {
    ...mapState({
      message: state => state.user.message,
    }),
  },
  methods: {
  },
};
</script>
<style>
.height-100 {
  height: 100%
}
</style>
